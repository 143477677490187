//@import '../node_modules/mdbreact/dist/scss/mdb';
@import './globals.scss';

body {
    color: $colorGray1 !important;
    margin: 0;
    padding: 0;
}

.progress {
    border-radius: 0;

    .progress-bar {
        background-color: $colorBlue2 !important;
    }
}

textarea {
    // border: solid 1px #08BFDD !important;
    resize: none;
}

.no-table-border {
    border: none;
}

.search-label{
  position: absolute;
  left: 18px;
  top: -44px;
}
//602
table {
    thead tr th {
        color: $tableOdd;
    }

    td { border-top: none !important; }
}
// .btn.btn-sm.btn-outline-info{
//     border: 2px solid $colorAqua1 !important;
//     font-weight: $boldish;
//     &:hover{
//         color: $colorWhite1 !important;
//         background: $colorAqua1 !important;
//     }
// }