//globals.scss

$colorAqua1: #08BFDD;
$colorAqua2: #5CE3F9;
$colorBlack1: #252628;
$colorBlack2: #3A3B3D;
$colorBlue1: #0076BB;
$colorBlue2: #08BFDD;
$colorGray1: #77777A;
$colorGray2: #ACACAE;
$colorGray3: #E3E4E5;
$colorGray4: #F7F7F8;
$colorGreen1: #77BC1F;
$colorGreen2: #00994D;
$colorOrange1: #FF671B;
$colorOrange2: #FF894E;
$colorOrange3: #F16724;
$colorPink1: #E24999;
$colorPurple1: rgb(0, 0, 0);
$colorRed1: #D2272E;
$colorWhite1: #FFFFFF;
$colorWhite2: rgba(247, 247, 248, 0.95);
$colorYellow1: #FDCD06;
$colorYellow2: #FFA400;
//Table
$tableOdd: #ACACAE;
//FONT
$font-stack-normal:'Roboto', Helvetica, Arial, sans-serif;
$font-stack-cond:'Roboto Condensed',  Helvetica, Arial, sans-serif !important;

//FONT-WEIGHTS
$light:300;
$regular:400;
$boldish:500;
$semibold:600;
$bold:700;
$black:900;

//MOBILE BREAKS 
$break-small: 850px;
$break-large: 1200px;

//BORDERS
$blue-border:solid 1px #08BFDD !important;
