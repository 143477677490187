@import '../../globals.scss';
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');


.cursor-pointer {
    cursor: pointer;
}

.app {
    overflow: hidden;
    // height: 100vh;
    text-align: center;
}

/************************
        Typography
************************/

body {
    color: $colorGray1 !important;
    font-family: $font-stack-normal;
}

h1,h2,h3 {
    font-family: $font-stack-cond;
}

h1.defaults-title {
    color: $colorAqua1;
    font-weight: $light;
}

.block > h2 {
    background: $colorAqua1;
    color: $colorWhite1;
    padding: 0.5rem;
    width: 100%;
}
.block > .row { padding: 1rem 0 2rem 0;}

b, strong {
    font-weight: $bold !important;
}


.App {
    text-align: center;
}

.navbar {
    height: 6vh;
    background-color: $colorAqua1;
    box-shadow: none;
    font-family: $font-stack-cond;
    .navbar-brand {
        font-size: 24px;
        cursor: auto;
    }
}

.dropdown .dropdown-menu .dropdown-item:hover {
    background-color: $colorAqua1;
}

/************************
    Forms
************************/
input.form-control, textarea.form-control {
    border: solid 0.1rem $colorAqua1;
    color: $colorGray1 !important;
}

/************************
    Buttons
************************/

.btn {
    text-transform: none !important;
}

.btn.btn-flatblock {
    box-shadow: none;
    font-size: 1.2rem;
    font-weight: $boldish;
    
    &.bg-orange1 {
        background-color: $colorOrange1;
        color: $colorWhite1;
        padding: 0.4em 0.8em;
        box-shadow: inset 0 0 0 1.5px $colorOrange1;
        &:hover{
            background-color: $colorWhite1;
            color: $colorOrange1;
            box-shadow: inset 0 0 0 1.5px $colorOrange1;
        }
      }
}
  .btn-hollow {
    font-size: .7rem !important;
    background: none;
    box-shadow: none;
    font-weight: $boldish;

    &:hover, &:active, &:focus {
        background: none;
        box-shadow: none !important;
    }
    &.truii-blue {
        box-shadow: inset 0 0 0 0px $colorBlue2;
        color: $colorAqua1;
        border: 2px solid $colorAqua1;
        &:disabled{
            box-shadow: inset 0 0 0 0px $colorGray1;
            color: $colorGray1;
            border: 2px solid $colorGray1;
        }
        &.active {
            background-color: $colorAqua1;
            color: #FFF;
            box-shadow: none !important;
        }
        &:hover {
            background-color: $colorAqua1;
            color: #FFF;
            box-shadow: none !important;
        }
    }
    &.btn-disabled {
        color: $colorGray1;
        border: 2px solid $colorGray1;
    }
}
.btn.btn-sm.btn-truii-blue {
    background: $colorBlue2;
    color: $colorWhite1;
    box-shadow: inset 0 0 0 1.5px $colorBlue2;
    &:hover{
        background: $colorWhite1;
        color: $colorBlue2;
    }
}
.btn.btn-sm.btn-truii-orange {
    background: $colorOrange1;
    color: $colorWhite1;
    box-shadow: inset 0 0 0 1.5px $colorOrange1;
    &:hover{
      background: $colorWhite1;
      color: $colorOrange1;
    }
}
// Switch for toggeling featured
.bs-switch.truii-blue input:checked + .slider{
    background-color: $colorBlue2;
  }
  .btn.scenario-btn.btn-view:hover{
    color: $colorAqua1 !important;
    background: $colorWhite1;
    box-shadow: inset 0 0 0 1.5px $colorAqua1;
  }

header .close { color: $colorGray2; }

/************************
        tables
************************/
table { border: none; }

%gray-table {
    background: $colorGray4;
    border: $colorAqua1 solid 1px;
    border-radius: 0.3rem;
}

.defaults-catchments-table, .scenario-interventions-table, .scenario-catchments-table {
    > tbody > tr:not(.expanding-row) {
        @extend %gray-table;

        > td {
            font-weight: 400;

            &:first-child {
                font-size: 1.1rem;
            }
        }
    }

    > tbody > .expanding-row {
        background: $colorWhite1;

        tbody > tr > td {
            border-bottom: $colorAqua1 solid 1px;
        }
    }

    td {
        padding: 0.2rem 0.75rem;
    }
}

//.defaults-catchments-table > tbody > tr, .scenario-interventions-table > tbody > tr {
//    @extend %gray-table;
//}
//.defaults-catchments-table > tbody > tr > td, .scenario-interventions-table > tbody > tr > td {
//    font-weight: 400;
//}
//.defaults-catchments-table > tbody > tr > td:first-child, .scenario-interventions-table > tbody > tr > td:first-child {
//    font-size: 1.1rem;
//}
//.defaults-catchments-table > tbody > .expanding-row, .scenario-interventions-table > tbody > .expanding-row {
//    background: $colorWhite1;
//}
//.defaults-catchments-table.table td {
//    padding: 0.2rem 0.75rem;
//}

.panel-contents {
    margin: 1rem;
}
.panel-contents h4 {
    color: $colorAqua1;
    font-size: 1rem;
    font-weight: 400;
    margin: 0 1rem 0.8rem 1rem;
    text-align: left;
}



.text-truii-aqua {
    color: $colorAqua1;
}
.bg-truii-aqua {
    background: $colorAqua1;
}



.btn-disabled {
    color: $colorGray1;
    border: 2px solid $colorGray2;
}

/************************
    dropdown menu
************************/
.dropdown .dropdown-menu .dropdown-item:hover,
.dropdown .dropdown-menu .dropdown-item:active {
    background-color: $colorAqua1 !important;
}

button.no-button {
    background: none !important;
    box-shadow: none;
    padding: .5rem; }
button.no-button:hover, button.no-button:active {
    background: none;
    box-shadow: none; }


/************************
    Media queries
************************/

@media (max-width:992px) {
    .defaults-catchments-table textarea.form-control {
        margin-bottom: 1rem;
        width: 96.5%;
    }
    .defaults-catchments-table .col-lg-8 h4 {
        padding-left: 1rem;
    }

}

@media (max-width:576px) {
    .defaults-catchments-table > tbody > tr > td:first-child {
        font-size: 0.9rem;
    }
    .defaults-catchments-table textarea.form-control {
        margin-bottom: 1rem;
        width: 94.5%;
    }
}

/************************
    Progress bars
************************/
.progress {
    height: 5px !important;
}


.popover.fade:not(.show) {
    opacity: 1 !important;

    > .arrow {
        top: calc((.5rem + 1px) * -1);

        &:before {
            top: 0;
            border-bottom-color: rgba(0, 0, 0, .25);
            border-top-width: 0;
            border-right-width: .5rem;
            border-bottom-width: .5rem;
            border-left-width: .5rem;
        }
        &:after {
            top: 1px;
            border-bottom-color: #FFF;
            border-top-width: 0;
            border-right-width: .5rem;
            border-bottom-width: .5rem;
            border-left-width: .5rem;
        }
    }

    &.bottom {
        margin-top: .5rem;
    }
}

.block-pointer-events {
    pointer-events: none;
}
.clickable {
    cursor: pointer;
}
